import React from 'react'
import { graphql, Link } from 'gatsby'
import BasicLayout from 'src/components/BasicLayout'

function MdxIndex({ data }) {
  const { edges: lessons } = data.allMdx
  return (
    <BasicLayout>
      {lessons.map(({ node }) => {
        const { title, section, tags } = node.frontmatter
        //if (title.length > 0 && tags !== null && !tags.includes("tutorial")) {
        if (title.length > 0) {
        return (
          <div key={node.id}>
            <h4><Link to={`${node.fields.slug}`}>{title}</Link></h4>
            <p><i>{node.excerpt}</i></p>
            <hr />
          </div>
        )
        }
      })}
    </BasicLayout>
  )
}

export default MdxIndex

export const pageQuery = graphql`
  query snlpIndex {
    allMdx(sort: {fields: frontmatter___title, order: ASC}, filter: {frontmatter: {section: {eq: "snlp-1"}}}) {
      edges {
        node {
          id
          excerpt(pruneLength: 200)
          fields { slug }
          frontmatter {
            title
            section
            tags
          }
        }
      }
    }
  }
`